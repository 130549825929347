// @ts-check
// Module w3c/l10n
// Looks at the lang attribute on the root element and uses it to manage the config.l10n object so
// that other parts of the system can localise their text
import { l10n } from "../core/l10n.js";
export const name = "w3c/l10n";
const additions = {
  en: {
    status_at_publication:
      "This section describes the status of this document at the time of its publication. Other documents may" +
      " supersede this document. A list of current PAiP Web publications and the latest revision of this technical" +
      " report can be found in the <a href='https://www.spec.paipweb.com/TR/'>PAiP Web technical reports index</a> at" +
      " https://www.spec.paipweb.com/TR/.",
  },
};

Object.keys(additions).forEach(key => {
  Object.assign(l10n[key], additions[key]);
});
